<template>
    <div class="card bs-5 mb-5 container">
        <div class="fl-te-c pb-3">
            <h4 v-html="'OUR WORK'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.ourWorkAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Our Work" ref="ourWorkAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddOurWork @success="formSuccess"></AddOurWork>
        </modal>

        <modal title="Edit Our Work" ref="ourWorkEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditOurWork :initial-data="editingItem" @success="formSuccess"></EditOurWork>
        </modal>

        <delete-modal ref="ourWorkDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Our Work <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import AddOurWork from './AddOurWork';
import EditOurWork from './EditOurWork';

export default {
    name       : 'OurWork',
    components : { AddOurWork, EditOurWork },
    data () {
        return {
            listUrl      : urls.ourWork.list,
            deleteUrl    : urls.ourWork.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'name',
                    sortField  : 'name',
                    title      : 'Name',
                    titleClass : ''
                },
                {
                    name       : 'link',
                    sortField  : 'link',
                    title      : 'Link',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.ourWorkAddModal.close();
            refs.ourWorkEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.ourWorkEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.ourWorkDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Our Work..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.ourWorkDeleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
