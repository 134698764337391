<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :initial-data="initialData">

<!--        <validated-vue-select class="col-12" label="Name" name="Name" v-model="model.name"-->
<!--                     :rules="rules.name" :disabled="loading" :options="nameOptions"/>-->

        <validated-input class="col-12" label="Link" name="Link" v-model="model.link"
                     :rules="rules.link" :disabled="loading"/>

        <btn class="trn" size="sm" icon="fa fa-save" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name : 'EditOurWork',

    props : { initialData : { type : Object } },

    data () {
        return {
            addUrl : urls.ourWork.addEdit,
            rules  : {
                name : {
                    required : true
                },
                link : {
                    required : true
                },
                icon : {
                    required : false
                }
            },
            nameOptions : [
                { label : 'Skill Training & Livelihood', value : 'Skill Training & Livelihood' },
                { label : 'Education', value : 'Education' },
                { label : 'Healthcare', value : 'Healthcare' }
            ]
        };
    },

    methods : {
        formSuccess () {
            // this.$notify('Successfully Edited Our Work..!', 'Success',
            //     {
            //         type : 'success'
            //     }
            // );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
